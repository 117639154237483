// Home.js
import React, { useState } from 'react';
import AktywneTextSection from './AktywneTextSection';
import AktywneIntSection from './AktywneIntSection';
import AktywneIconSection from './AktywneIconSection';
import AktywneEventSection from './AktywneEventSection';
import AktywneAppSection from './AktywneAppSection';
import AktywneCitySection from './AktywneCitySection';
import AktywneSponsorsSection from './AktywneSponsorsSection';
import { useMediaQuery } from 'react-responsive';
import './Home.css'; // Zakładamy, że style będą w osobnym pliku CSS

function Home() {
    // const [isModalVisible, setIsModalVisible] = useState(true);

    // const isMobile = useMediaQuery({ maxWidth: 640 });
    // const isTablet = useMediaQuery({ minWidth: 641, maxWidth: 1024 });
    // const isDesktop = useMediaQuery({ minWidth: 1025 });
  
    // const getBannerSrc = () => {
    //   if (isMobile) return '/images/banner_640_blackweek.jpg';
    //   if (isTablet) return '/images/banner_800_blackweek.jpg';
    //   if (isDesktop) return '/images/banner_1280_blackweek.jpg';
    //   return '/images/banner.jpg'; // Default fallback
    // };

    // const closeModal = (e) => {
    //     const overlay = document.querySelector(".modal-overlay");
    
    //     // Jeśli kliknięcie jest na overlay lub zamykamy przez przycisk
    //     if (overlay && (e.target === overlay || e.target.closest(".close"))) {
    //         console.log(e.target);
    //         overlay.classList.add("hidden");
    //         setTimeout(() => setIsModalVisible(false), 300); // Czas musi odpowiadać CSS transition
    //     }
    // };
    

    return (
        <main>
           {/* {isModalVisible && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal">
                        <button className="close-button close" onClick={closeModal}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 557.9 554.9" width="24" height="24">
                                <g id="Outline">
                                    <path
                                        d="M455.5,533.6c-22-.1-44.2-8.7-59.9-24.4l-118.7-118.7-118.8,118.8c-31,31-81,33.3-111.6,5.3-32.5-29.7-33.6-82.7-2.5-113.7l121.6-121.6s-121.6-121.6-121.6-121.6c-31.4-31.4-29.7-84.9,3.3-114.4,31.1-27.8,79.5-25.4,110.3,5.4l119.4,119.4,119.4-119.4c30.7-30.8,79.2-33.2,110.2-5.4,32.9,29.4,34.4,83.3,3.3,114.4l-121.6,121.6s121.6,121.6,121.6,121.6c31.3,31.3,30,83.9-2.5,113.7-14.3,13.1-32.9,19.2-51.7,19.1Z"
                                        fill="black"
                                        stroke="white"
                                        strokeWidth="24"
                                        strokeMiterlimit="10"
                                    />
                                </g>
                            </svg>
                        </button>
                        <a href="https://www.centrumrowerowe.pl/okazje/black-friday/" target="_blank" rel="noopener noreferrer">
                            <img src={getBannerSrc()} alt="Centrum Rowerowe" className="advertisement-image" />
                        </a>
                    </div>
                </div>
            )} */}

            <section className="text">
                <AktywneTextSection />
            </section>

            <section className="text">
                <AktywneIntSection />
            </section>

            <section className="text">
                <AktywneIconSection />
            </section>

            <section className="text">
                <AktywneEventSection /> 
            </section>

            <section className="text">
                <AktywneAppSection />
            </section>

            <section className="text">
                <AktywneCitySection />
            </section>

            <section className="text">
                <AktywneSponsorsSection />
            </section>
        </main>
    );
}

export default Home;
